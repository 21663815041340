export default [
    {
        src: '/landing/gov/IMAU.webp',
        alt: 'Imam Abdulrahman Bin Faisal University'
    },
    {
        src: '/landing/gov/SFDA.webp',
        alt: 'Saudi Food and Drug Authority'
    },
    {
        src: '/landing/gov/MOEP.webp',
        alt: 'Ministry Of Economy & Planning'
    },
    {
        src: '/landing/gov/MOHU.webp',
        alt: 'Ministry Of Hajj And Umrah'
    },
    {
        src: '/landing/gov/SCTC.webp',
        alt: 'Communication, Space & Technology Commission'
    },
    {
        src: '/landing/gov/NEOM.webp',
        alt: 'NEOM'
    },
    {
        src: '/landing/gov/GOSI.webp',
        alt: 'General Organization for Social Insurance'
    },
    {
        src: '/landing/gov/HH.webp',
        alt: 'Health Holding'
    },
    {
        src: '/landing/gov/TGA.webp',
        alt: 'Transport General Authority'
    },
    {
        src: '/landing/gov/SDB.webp',
        alt: 'Social Development Bank'
    },
    {
        src: '/landing/gov/GEA.webp',
        alt: 'General Entertainment Authority'
    },
    {
        src: '/landing/gov/KAFD.webp',
        alt: 'King Abdullah Financial District'
    },
    {
        src: '/landing/gov/MAWANI.webp',
        alt: 'Saudi Ports Authority'
    },
    {
        src: '/landing/gov/KS_RDA.webp',
        alt: 'King Salman Royal Reserve Development Authority'
    },
    {
        src: '/landing/gov/NCVC.webp',
        alt: 'National Center for Vegetation Cover Development and Combating Desertification'
    }
]
