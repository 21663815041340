export default [
    {
        src: '/landing/lawfirms/OMAR.webp',
        alt: 'OMAR'
    },
    {
        src: '/landing/lawfirms/MAZRUA.webp',
        alt: 'MAZRUA'
    },
    {
        src: '/landing/lawfirms/BABTAIN.webp',
        alt: 'BABTAIN'
    },
    {
        src: '/landing/lawfirms/SEFRI.webp',
        alt: 'SEFRI'
    },
    {
        src: '/landing/lawfirms/FUDALI.webp',
        alt: 'FUDALI'
    },
    {
        src: '/landing/lawfirms/BATIC.webp',
        alt: 'BATIC'
    },
    {
        src: '/landing/lawfirms/ASEEL.webp',
        alt: 'ASEEL'
    },
    {
        src: '/landing/lawfirms/HFW.webp',
        alt: 'HFW'
    },
    {
        src: '/landing/lawfirms/HSF.webp',
        alt: 'HSF'
    },
    {
        src: '/landing/lawfirms/ALOTHMAN.webp',
        alt: 'ALOTHMAN'
    },
    {
        src: '/landing/lawfirms/RSS.webp',
        alt: 'RSS'
    },
    {
        src: '/landing/lawfirms/STAT.webp',
        alt: 'STAT'
    },
    {
        src: '/landing/lawfirms/RASSEEN.webp',
        alt: 'RASSEEN'
    },
    {
        src: '/landing/lawfirms/FRYAN.webp',
        alt: 'FRYAN'
    },
    {
        src: '/landing/lawfirms/SABA.webp',
        alt: 'SABA'
    },
    {
        src: '/landing/lawfirms/ALFAISAL.webp',
        alt: 'ALFAISAL'
    },
    {
        src: '/landing/lawfirms/KNA.webp',
        alt: 'KNA'
    },
    {
        src: '/landing/lawfirms/DAG.webp',
        alt: 'DAG'
    },
    {
        src: '/landing/lawfirms/SAADQHT.webp',
        alt: 'SAADQHT'
    }
]
